import tokens from './tokens'
import { PoolConfig, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    sousId: 0,
    stakingToken: tokens.cake,
    earningToken: tokens.cake,
    contractAddress: {
      96: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
      56: '0x587A8a7194fEEd0cfB531CF767A2e6fb1b2A073C',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '6.64',
    sortOrder: 1,
    isFinished: false,
    depositFee:1,
    harvestInterval:72
  
  },
  
]

export default pools
