import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  /**
   * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
   */
   {
    pid: 0,
    lpSymbol: 'LSWAP',
    lpAddresses: {
      96: '0x9C21123D94b93361a29B2C2EFB3d5CD8B17e0A9e',
      56: '0x3F8a14f5a3Ee2F4A3Ed61cCF5EEA3c9535C090C8',
    },
    token: tokens.syrup,
    quoteToken: tokens.wbnb,
    depositFee:1,
    harvestInterval:8
  },
  {
    pid: 1,
    lpSymbol: 'LSWAP-BNB LP',
    lpAddresses: {
      96: '0x3ed8936cAFDF85cfDBa29Fbe5940A5b0524824F4',
      56: '0x49e6B169B1042FD210248bE252A69245BE282c9a',
    },
    token: tokens.cake,
    quoteToken: tokens.wbnb,
    depositFee:5,
    harvestInterval:8
  },
  {
    pid: 2,
    lpSymbol: 'BUSD-BNB LP',
    lpAddresses: {
      96: '',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    },
    token: tokens.busd,
    quoteToken: tokens.wbnb,
    depositFee:5,
    harvestInterval:8
  },
  {
    pid: 3,
    lpSymbol: 'LOOP-BNB LP',
    lpAddresses: {
      96: '',
      56: '0x204c5311b9F040D40425C0ce8b5BdDAE1CB50974',
    },
    token: tokens.loop,
    quoteToken: tokens.wbnb,
    depositFee:0,
    harvestInterval:8
  }
  
  
  /**
   * 
   * V3 by order of release (some may be out of PID order due to multiplier boost)
   */
  
  
  
]

export default farms
