export default {
  masterChef: {
    96: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    56: '0x587A8a7194fEEd0cfB531CF767A2e6fb1b2A073C',
  },
  sousChef: {
    96: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    56: '0x18E128B9492b4D02e2bFbA07868F4cef2993Eb55',
  },
  lotteryV2: {
    96: '0x5790c3534F30437641541a0FA04C992799602998',
    56: '0x150f993F044c8E85A32390a9D71C53f06D936b0a',
  },
  multiCall: {
    56: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
    96: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
  },
  pancakeProfile: {
    56: '0xD293a7064e7E3B61bfbF2728F976d2500206dc73',
    96: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
  },
  pancakeRabbits: {
    56: '0xB7a9250B4c70D7E5b7f38468007bD53688dF0eE1',
    96: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
  },
  bunnyFactory: {
    56: '0x51a66D22dF595aF03285239CcD51B3eD8CAC3543',
    96: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
  },
  claimRefund: {
    56: '0xd90C439DE0dc916B32B7F88BB1811A738f47CA7B',
    96: '',
  },
  pointCenterIfo: {
    56: '0x879A20c1fF7BF9c8D5e4eb2218288bc99Bc92bfb',
    96: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
  },
  bunnySpecial: {
    56: '0xFee8A195570a18461146F401d6033f5ab3380849',
    96: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  tradingCompetition: {
    56: '0xd718baa0B1F4f70dcC8458154042120FFE0DEFFA',
    96: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
  },
  easterNft: {
    56: '0xf0607DcCdfF91654252BC2bfDd2D366e52506490',
    96: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
  },
  dexTokenVault: {
    56: '0xD270d9b58FE48DB57C5A84658e8741cF52bC381A',
    96: '',
  },
  predictions: {
    56: '0xc78Bda784F84649329C006930e8811178d1899aa',
    96: '0x4f3140C74789F1D809420343ea83BcE52B7bbAA5',
  },
  chainlinkOracle: {
    56: '0x2514895c72f50D8bd4B4F9b1110F0D6bD2c97526',
    96: '',
  },
  bunnySpecialCakeVault: {
    56: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    96: '',
  },
  bunnySpecialPrediction: {
    56: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    96: '',
  },
  farmAuction: {
    56: '0xb92Ab7c1edcb273AbA24b0656cEb3681654805D2',
    96: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
  },
}
